import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    font-family: "Work Sans", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    color: #000000;
  }
`
