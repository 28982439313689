import React, { useState, useEffect, useCallback } from "react"
import { DateTime } from "luxon"
import styled from "styled-components"

const StyledClock = styled.div`
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: ${props => (props.isOpen ? "#0029ff" : "#ff0000")};
`

export default function Clock() {
  const [isOpen, setIsOpen] = useState(true)

  const [time, setTime] = useState([
    DateTime.local().setZone("CET"),
    DateTime.local().setZone("Europe/Moscow"),
  ])

  const workingTime = DateTime.fromObject({
    hour: "19",
    minute: "00",
    zone: "cet",
  })

  useEffect(() => {
    setIsOpen(time[0] < workingTime)
  },[time, workingTime])

  const tick = useCallback(() => {
    setTime([
      DateTime.local().setZone("CET"),
      DateTime.local().setZone("Europe/Moscow"),
    ])
    setIsOpen(time[0] < workingTime)
  }, [time, workingTime])

  useEffect(() => {
    let intervalId = setInterval(() => tick(), 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [time, tick])

  return (
    <StyledClock isOpen={isOpen}>
      {time[0].isValid &&
        `${time[0].toFormat("dd/MM/yyyy")} 
        ${time[0].zone.name} 
        ${time[0].toFormat("TT")} ${time[1].toFormat("(ZZZZ) TT")}`}
    </StyledClock>
  )
}
