import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useRef, useEffect } from "react"
import styled from "styled-components"

import Clock from "./clock"

const StyledHeader = styled.header`
  z-index: 15;
  width: 849px;
  height: 77px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;

  transition: top 0.3s;

  @media only screen and (max-width: 840px) {
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    max-height: 150px;
  }
`

const Logo = styled(Link)`
  font-family: "AT Osmose", sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 86px;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease;
  overflow: hidden;
  @media only screen and (max-width: 760px) {
    font-size: 62px;
  }
`

const Navigation = styled.nav`
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 18px;

  @media only screen and (max-width: 760px) {
    padding-left: 18px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    padding-bottom: 7px;
  }
`

const LinksList = styled.div`
  transition: all 0.3s ease;

  @media only screen and (max-width: 760px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
`

const StyledLink = styled(Link)`
  padding-top: 9px;
  padding-bottom: 9px;
  display: inline-block;
  text-decoration: none;
  color: #000000;
  transition: color 0.3s ease-in-out;

  & + a,
  & + button {
    margin-left: 30px;
  }

  &:hover,
  &:active {
    color: #0029ff;
  }
  @media only screen and (max-width: 760px) {
    font-size: 14px;
  }
`

const StyledLinkButton = styled.button`
  padding: 9px 0;
  display: inline-block;
  text-decoration: none;
  color: #000000;
  transition: color 0.3s ease-in-out;
  background: transparent;
  border: none;
  & + a,
  & + button {
    margin-left: 30px;
  }

  &:hover,
  &:active {
    color: #0029ff;
  }
  @media only screen and (max-width: 760px) {
    font-size: 14px;
  }
`

const Header = ({ onFilter, mainPage }) => {
  const header = useRef()

  let prevScrollpos = typeof window !== "undefined" && window.pageYOffset

  const onScrollHandler = () => {
    if (!mainPage) {
      return
    }

    const currentScrollPos = window.pageYOffset
    if (prevScrollpos > currentScrollPos) {
      header.current.style.top = "0"
    } else {
      header.current.style.top = window.innerWidth >= 760 ? "-77px" : "-155px"
    }

    prevScrollpos = currentScrollPos
  }

  useEffect(() => {
    window.addEventListener("scroll", onScrollHandler)

    return () => {
      window.removeEventListener("scroll", onScrollHandler)
    }
  })

  return (
    <StyledHeader ref={header}>
      <Logo to="/">SHOPLIFT</Logo>
      <Navigation>
        <LinksList>
          {!mainPage ? (
            <>
              <StyledLink
                to="/"
                state={{ fromContacts: !mainPage, target: "print" }}
              >
                print
              </StyledLink>
              <StyledLink
                to="/"
                state={{ fromContacts: !mainPage, target: "digital" }}
              >
                digital
              </StyledLink>
            </>
          ) : (
            <>
              <StyledLinkButton onClick={() => onFilter("print")}>
                print
              </StyledLinkButton>
              <StyledLinkButton onClick={() => onFilter("digital")}>
                digital
              </StyledLinkButton>
            </>
          )}
          <StyledLink to="/contacts">contact</StyledLink>
        </LinksList>
        <Clock />
      </Navigation>
    </StyledHeader>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
